import React, {useEffect, useState} from "react";
import {useNavigate, NavLink} from "react-router-dom";
import {useSelector} from 'react-redux';
import {makeStyles} from "@mui/styles";
import {DataGrid} from '@mui/x-data-grid';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import {Cancel} from "@mui/icons-material";
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {Dots} from "react-activity";
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {API_ENDPOINT, COLOR_BRAND} from "../../../consts";
import Status from "./Status";

const useStyles = makeStyles((theme) => ({
    container_outer: {
        display: "flex",
        width: "100%",
        backgroundColor: "#fafafa",
        color: "#000",
        borderBottom: "1px solid rgba(0,0,0,.2)",
        flexDirection: "column"
    },
    container_outer_save_button: {
        display: "flex",
        width: "96%",
        padding: "2%",
        justifyContent: "center"
    },
    container_inner: {
        display: "flex",
        width: "96%",
        padding: "2%",
        paddingBottom: "7%",
        color: "#000",
        flexDirection: "row"
    },
    container_outer_save_button: {
        display: "flex",
        width: "96%",
        padding: "2%",
        justifyContent: "center"
    },
    column_left: {
        flex: 1
    },
    column_center: {
        display: "flex",
        flex: 4,
        flexDirection: "row"
    },
    column_center_half: {
        flex: 1
    },
    column_right: {
        flex: 1,
        flexDirection: "column",
        display: "flex"
    },
    input_string: {
        width: "80%",
        maxWidth: 750,
        marginBottom: 20,
        backgroundColor: "#ffffff"
    },
}));


export default function Ingredients(props) {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [isSnackbar, setIsSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [data, setData] = useState(props.data);
    const [isLoading, setIsLoading] = useState(false);
    const [ingredientResults, setIngredientResults] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [ingredientsUsed, setIngredientsUsed] = useState([]);
    const [keyIngredients, setKeyIngredients] = useState(0);
    const [list, setList] = useState("");
    const [listArray, setListArray] = useState([]);
    const [status, setStatus] = useState('BROWSE');
    const [searchString, setSearchString] = useState("");
    const [searchTimer, setSearchTimer] = useState(-1);

    const navigate = useNavigate();

    const TIMER_LIMIT = 1;
    var timer;

    useEffect(() => {
        filterIngredients(props.data.ingredients);
    }, []);

    useEffect(() => {
        console.log(searchString);
        if (searchString.length > 0) setIsLoading(true);
        setSearchTimer(TIMER_LIMIT);
        if (searchString.length <= 1) {
            setIngredients([]);
            setIsLoading(false);
        }
        clearTimeout(timer);
    }, [searchString]);

    useEffect(() => {
        console.log("searchTimer: " + searchTimer);
        if (searchString.length === 0) {
            setIngredientResults([]);
        }
        if (searchTimer <= 0 && searchString.length > 1) {
            console.log("START LOOKUP");
            getProductIngredients();
        } else if (searchTimer > 0) {
            timer = setTimeout(() => {
                setSearchTimer(prev => prev - 1);
            }, 1000);
        }
    }, [searchTimer]);

    const filterIngredients = (data) => {
        let ingredientsUnused = [];
        let ingredientsUsed = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].isSaved === 1) {
                ingredientsUsed[ingredientsUsed.length] = data[i];
            } else {
                ingredientsUnused[ingredientsUnused.length] = data[i];
            }
        }
        setIngredients(ingredientsUnused);
        setIngredientsUsed(ingredientsUsed);
    };

    const saveForm = () => {
        setIsSaving(true);
        axios.post(API_ENDPOINT + "?func=update_product", {
            id_updatedBy: user.id,
            id: props.data.id,
            section: props.data.section,
            status: status,
            list: list,
            ingredients: JSON.stringify(ingredientsUsed)
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                if (res.status === 200) {
                    var tmpData = data;
                    tmpData.ingredients_status = 1;
                    setData(tmpData);
                    setIngredientsUsed(res.data);
                    setList("");
                    setListArray([]);
                    setSnackbarMessage("Product saved successfully");
                    setIsSnackbar(true);
                } else {
                }
                setIsSaving(false);
            });
    };

    const handleStatusChange = (e) => {
        let newStatus = e.target.value;
        setStatus(newStatus);
        if (newStatus == "BROWSE") {
            //getProductIngredients();
        }
    };

    const getProductIngredients = async () => {
        console.info(API_ENDPOINT + '?func=get_product_ingredients&value=' + searchString + '&id=' + props.id);
        await axios.get(API_ENDPOINT + '?func=get_product_ingredients&value=' + searchString + '&id=' + props.id)
            .then(res => {
                console.log(res.data);
                if (res.status === 200) {
                    console.log('data', res.data, res.data.length);
                    setIngredientResults([...res.data, { id: -999, name: searchString, label: `Add "${searchString}" as a new ingredient` }]);

                }
                setIsLoading(false);
            });
    };

    const cancelForm = () => {

        //filterIngredients(props.data);
    };

    const snackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbar(false);
    };

    return (
        <>
            <div className={classes.container_outer}>
                <div className={classes.container_inner}>
                    <div className={classes.column_left}>
                        <b>{props.label} ({ingredientsUsed.length})</b><br/><br/>
                    </div>
                    <div className={classes.column_center}>
                        <div className={classes.column_center_half}>
                            {(
                                <>
                                    <ToggleButtonGroup
                                        value={status}
                                        exclusive
                                        onChange={handleStatusChange}
                                        aria-label="text alignment"
                                        style={{marginBottom: 10, display: "flex", alignItems: "center"}}
                                    >
                                        <ToggleButton name="status" value="BROWSE" size="small" color="primary" aria-label="centered">
                                            SEARCH
                                        </ToggleButton>
                                        <ToggleButton name="status" value="IMPORT" size="small" color="primary" aria-label="centered">
                                            IMPORT
                                        </ToggleButton>
                                        {isLoading && (
                                            <Dots style={{marginLeft: 20}}/>
                                        )}
                                    </ToggleButtonGroup><br/>
                                    {status == "IMPORT" && (
                                        <TextField
                                            id="standard-basic"
                                            label={"Comma space-separated ingredients list (" + listArray.length + ") eg. 'aqua, glycerin, toluene-2,5-diamine sulfate'"}

                                            multiline={true}
                                            rows={7}
                                            style={{backgroundColor: "#fff", marginBottom: 5}}
                                            className={classes.input_string}
                                            value={list}
                                            onChange={value => {
                                                setList(value.target.value);
                                                setListArray(value.target.value.split(", "));
                                            }}
                                        />
                                    )}
                                    {status == "BROWSE" && (
                                        <>
                                            <Autocomplete
                                                key={keyIngredients}
                                                disablePortal
                                                id="combo-box-demo"
                                                className={classes.input_string}
                                                style={{marginBottom: 15}}
                                                options={ingredientResults}
                                                renderOption={(props, option) => (
                                                    <li {...props}>
                                                        {option.no_products && (<Typography><b>{option.name}</b> - {option.no_products} products</Typography>)}
                                                        {option.isSynonym && (<Typography><i>{option.label}</i> - <b>{option.name}</b></Typography>)}
                                                        {option.id === -999 && (<Typography>{option.label}</Typography>)}
                                                    </li>
                                                )}
                                                renderInput={(params) => <TextField value={searchString} {...params} label="Find an ingredient" style={{backgroundColor: "#fff"}}/>}
                                                onChange={(event, newValue) => {

                                                    if (newValue?.id === -999) {
                                                        console.warn("Add new ingredient: " + searchString);

                                                        axios.post(API_ENDPOINT + "?func=create_ingredient", {
                                                            name: newValue.name,
                                                        }, {
                                                            headers: {
                                                                'Content-Type': 'application/x-www-form-urlencoded'
                                                            }
                                                        }).then(res => {
                                                            if (res.status === 200) {
                                                                var tempData = [...ingredientsUsed];
                                                                tempData[tempData.length] = {id: res.data, name: newValue.name};
                                                                setIngredientResults([]);
                                                                setIngredientsUsed(tempData);
                                                                setKeyIngredients(prev => prev + 1);
                                                            }
                                                        })

                                                    } else if (newValue) {
                                                        var tempData = [...ingredientsUsed];
                                                        tempData[tempData.length] = newValue;
                                                        setIngredientResults([]);
                                                        setIngredientsUsed(tempData);
                                                        setKeyIngredients(prev => prev + 1);
                                                    }
                                                }}
                                                onInputChange={(event, newValue) => {
                                                    console.info("I AM 32");
                                                    setSearchString(newValue);
                                                }}
                                                noOptionsText={'Loading...'}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            <Stack direction="row" spacing={1} className={classes.input_string} style={{backgroundColor: "transparent", flexWrap: "wrap", marginTop: 40}}>
                                {ingredientsUsed.map((item, index) => (
                                    <div>
                                        <Chip
                                            label={<Typography style={{userSelect: 'text'}}>{item?.name}</Typography>}
                                            onDelete={() => {
                                                var tempData = [...ingredientsUsed];
                                                for (var i = 0; i < tempData.length; i++) {
                                                    if (tempData[i].id === item?.id) {
                                                        tempData.splice(i, 1);
                                                    }
                                                }
                                                setIngredientResults([]);
                                                setIngredientsUsed(tempData);
                                                setKeyIngredients(prev => prev + 1);
                                            }}
                                            onClick={() => navigate("/ingredients?id=" + item?.id)}
                                            deleteIcon={<Cancel/>}
                                            sx={{justifyContent: "flex-start", marginBottom: 1}}
                                        />
                                    </div>
                                ))}
                            </Stack>
                        </div>
                    </div>
                    <div className={classes.column_right}>
                        <Status isSaving={isSaving}  data={data} section={props.section}/>
                    </div>
                </div>
                <div className={classes.container_outer_save_button}>
                    <Button style={{color: "#fff", backgroundColor: COLOR_BRAND}} variant="contained" size="medium" onClick={saveForm}>SAVE</Button>
                </div>
            </div>
            <Snackbar
                open={isSnackbar}
                autoHideDuration={1000}
                onClose={snackbarClose}
                message={snackbarMessage}
                action={<div/>}
                key={Date.now()}
            />
        </>
    );
}
