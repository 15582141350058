import React, {useEffect, useState} from "react";
import {useNavigate, NavLink} from "react-router-dom";
import {useSelector} from 'react-redux';
import {makeStyles} from "@mui/styles";
import {AddPhotoAlternate, Flag, Edit} from "@mui/icons-material";
import Button from '@mui/material/Button';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import {Dots} from "react-activity";
import Avatar from '@mui/material/Avatar';
import {API_ENDPOINT, IMAGES_ENDPOINT, COLOR_BRAND, GOOGLE_BUCKET} from "../../../consts";
import Status from "./Status";
import Resizer from "react-image-file-resizer";

const useStyles = makeStyles((theme) => ({
    container_outer: {
        display: "flex",
        width: "96%",
        padding: "2%",
        paddingBottom: "5%",
        backgroundColor: "#fafafa",
        color: "#000",
        borderBottom: "1px solid rgba(0,0,0,.2)",
        flexDirection: "row"
    },
    container_outer_save_button: {
        display: "flex",
        width: "96%",
        padding: "2%",
        justifyContent: "center",
        marginTop: 40
    },
    column_left: {
        flex: 1
    },
    column_center: {
        display: "flex",
        flex: 4,
        flexDirection: "column"
    },
    column_right: {
        flex: 1,
        flexDirection: "column",
        display: "flex"
    },
    images_container: {
        display: "flex",
        flex: 4,
        flexDirection: "row",
        marginBottom: 80
    },
    new_image: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 150,
        height: 150,
        borderRadius: 75,
        backgroundColor: "#00000012",
        border: "1px solid rgba(0,0,0,.23);",
        cursor: "pointer",
        color: COLOR_BRAND,
        fontSize: 23,
        fontWeight: "bold"
    },
    inputFile: {
        display: "none",
        position: "absolute",
        top: 0
    },
}));


export default function (props) {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [isSaving, setIsSaving] = useState(false);
    const [data, setData] = useState(props.data);
    const [isLoading, setIsLoading] = useState(false);
    const [isSnackbar, setIsSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [selectedImage2, setSelectedImage2] = useState("");
    const [selectedImageFormFile, setSelectedImageFormFile] = useState(null);
    const [selectedImageFormFile2, setSelectedImageFormFile2] = useState(null);

    useEffect(() => {
        if (props.data.image_front?.length > 0) setSelectedImage((props.data.image_front.includes('front') ? GOOGLE_BUCKET : IMAGES_ENDPOINT) + "products/" + props.data.image_front + "?t=" + Date.now());
        if (props.data.image_back?.length > 0) setSelectedImage2((props.data.image_back.includes('back') ? GOOGLE_BUCKET : IMAGES_ENDPOINT) + "products/" + props.data.image_back + "?t=" + Date.now());
    }, []);


    const resizeImage = (file, maxWidth = 3200) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                maxWidth,
                9999,
                "JPEG",
                80,
                0,
                (file) => {
                    resolve(file);
                },
                "file",
            );
        });
    }

    const uploadPhoto = async ({target: {files}}) => {
        if (files && files[0]) {
            try {
                let resizedFile = await resizeImage(files[0]);
                if (resizedFile.size > 4000000) {
                    resizedFile = await resizeImage(files[0], 1800);
                }
                setSelectedImage(window.URL.createObjectURL(resizedFile));
                setSelectedImageFormFile(resizedFile);
            } catch (err) {
                console.log(err);
                alert ("Error resizing image");
            }
        }
    };

    const uploadPhoto2 = async ({target: {files}}) => {
        if (files && files[0]) {
            try {
                let resizedFile = await resizeImage(files[0]);
                if (resizedFile.size > 4000000) {
                    resizedFile = await resizeImage(files[0], 1800);
                }
                setSelectedImage2(window.URL.createObjectURL(resizedFile));
                setSelectedImageFormFile2(resizedFile);
            } catch (err) {
                console.log(err);
                alert("Error resizing image");

            }
        }
    };

    const saveForm = async () => {
        try {
            setIsSaving(true);
            if (selectedImageFormFile) {
                setIsLoading(true);
                const options = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent;
                        //setImageUploadProgress(Math.floor(loaded * 100 / total));
                    }
                }
                const formData = new FormData();
                formData.append("file", selectedImageFormFile);
                formData.append("id_product", props.id);
                formData.append("id_updatedBy", user.id);
                formData.append("image_side", "front");
                axios.post(API_ENDPOINT + "?func=upload_product_image", formData, options)
                    .then(res => {
                        if (res.status === 200) {
                            setIsLoading(false);

                            console.info(res.data);
                            setSnackbarMessage("Front image saved successfully");
                            setIsSnackbar(true);
                        }
                    });
            }
            if (selectedImageFormFile2) {
                setIsLoading(true);
                const options = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent;
                        //setImageUploadProgress(Math.floor(loaded * 100 / total));
                    }
                }
                const formData = new FormData();
                formData.append("file", selectedImageFormFile2);
                formData.append("id_product", props.id);
                formData.append("id_updatedBy", user.id);
                formData.append("image_side", "back");
                axios.post(API_ENDPOINT + "?func=upload_product_image", formData, options)
                    .then(res => {
                        if (res.status === 200) {
                            console.info(res.data);
                            setIsLoading(false);

                            setSnackbarMessage("Back image saved successfully");
                            setIsSnackbar(true);
                        }
                    });
            }


            var tmpData = data;
            tmpData.productImages_status = 1;
            setData(tmpData);
            setIsSaving(false);
        } catch (error) {
            // Handle errors here
        }
    };

    const cancelForm = () => {
        //
        //filterPackaging(props.data);
    };

    const snackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbar(false);
    };

    return (
        <>
            <div className={classes.container_outer}>
                <div className={classes.column_left}>
                    <b>{props.label}</b>
                </div>
                <div className={classes.column_center}>
                    <div className={classes.images_container}>
                        <input id="file-upload" type="file" className={classes.inputFile} onChange={uploadPhoto}/>
                        <label for="file-upload" style={{cursor: "pointer", fontSize: 12, textAlign: "center"}}>
                            {selectedImage.length > 0 ? (
                                <Avatar sx={{width: 150, height: 150}} src={selectedImage.length > 0 ? selectedImage : ""}/>
                            ) : (
                                <div className={classes.new_image}>
                                    <AddPhotoAlternate style={{color: COLOR_BRAND}}/>
                                </div>
                            )}
                            Front
                        </label>
                        <div style={{width: 20, height: "100%", display: "inline-block"}}/>
                        <input id="file-upload2" type="file" className={classes.inputFile} onChange={uploadPhoto2}/>
                        <label for="file-upload2" style={{cursor: "pointer", fontSize: 12, textAlign: "center"}}>
                            {selectedImage2.length > 0 ? (
                                <Avatar sx={{width: 150, height: 150}} src={selectedImage2.length > 0 ? selectedImage2 : ""}/>
                            ) : (
                                <div className={classes.new_image}>
                                    <AddPhotoAlternate style={{color: COLOR_BRAND}}/>
                                </div>
                            )}
                            Back
                        </label>
                    </div>
                    <div className={classes.container_outer_save_button}>
                        {isLoading ? (
                            <Dots/>
                        ) : (
                            <>
                                <Button style={{color: "#fff", backgroundColor: COLOR_BRAND}} variant="contained" size="medium" onClick={saveForm}>SAVE</Button>
                            </>
                        )}
                    </div>
                </div>
                <div className={classes.column_right}>
                    <Status isSaving={isSaving} data={data} section={props.section}/>
                </div>
            </div>
            <Snackbar
                open={isSnackbar}
                autoHideDuration={1500}
                onClose={snackbarClose}
                message={snackbarMessage}
                action={<div/>}
                key={Date.now()}
            />
        </>
    );
}
